const shoppingPackage = {
  namespaced: true,
  state: {
    // 缓存一份旧数据
    oldList: null,
    // 赠品数据
    gift: [],
  },
  getters: {
    oldList: (state) => {
      return state.oldList
    },
    gift: (state) => {
      return state.gift
    },
  },
  actions: {
    setOldList({ commit }, oldList) {
      return new Promise((resolve) => {
        commit('SET_OLD_LIST', oldList)
        resolve()
      })
    },
    setGift({ commit }, gift) {
      return new Promise((resolve) => {
        commit('SET_GIFT', gift)
        resolve()
      })
    },
  },
  mutations: {
    SET_OLD_LIST(state, oldList) {
      state.oldList = oldList
    },
    SET_GIFT(state, gift) {
      state.gift = gift
    },
  },
}
export default shoppingPackage
