import { Toast } from 'vant'
import { BizState } from '@/const/common'

/**
 * 分页列表混入
 */
export default {
  data() {
    return {
      loading: false,
      finished: false,
      list: [],
      param: {
        objQuery: {},
        page: 0,
        size: 10,
        currentPage: 0,
        pageSize: 10,
      },
    }
  },
  methods: {
    getList() {
      this.param.page++
      this.param.currentPage++
      this.setParam()
      this.loading = true
      this.listApi()
        .then((res) => {
          if (res.respCode === BizState.success) {
            if (Array.isArray(res.respData)) {
              this.finished = true
              this.list = res.respData
            } else {
              this.finished =
                res.respData.total <= res.respData.current * res.respData.size
              this.list = [...this.list, ...res.respData.records]
            }
          } else {
            Toast.fail(res.respDesc)
            this.finished = true
          }
        })
        .catch(() => {
          this.finished = true
        })
        .finally(() => {
          this.loading = false
        })
    },
    /**
     * 此hook在获取列表前调用可设置请求参数
     */
    setParam() {},
    resetPage() {
      this.list = []
      this.param.page = 0
      this.param.currentPage = 0
      this.getList()
    },
    /**
     * 业务覆盖此方法
     */
    listApi: () => {
      return new Promise((resolve, reject) => {
        resolve({})
      })
    },
  },
}
