<template>
  <div class="last-step-container">
    <van-image :src="'data:image/png;base64,' + qrImg"></van-image>
  </div>
</template>

<script>
export default {
  props: {
    qrImg: {
      type: String,
      default: null,
    },
  },
}
</script>

<style scoped lang="less">
.last-step-container {
  display: flex;
  justify-content: center;
}
</style>
