export const activityRoute = [
  {
    path: '/activity/openDoor',
    name: 'activityOpenDoor',
    component: () => import('@/views/24openDoorRed/openDoor.vue'),
    meta: { title: '充值活动' },
  },
  {
    path: '/activity/lottery',
    name: 'activityLottery',
    component: () => import('@/views/24openDoorRed/lottery.vue'),
    meta: { title: '抽奖' },
  },
  {
    path: '/activity/lotteryAddress',
    name: 'activityLotteryAddress',
    component: () => import('@/views/24openDoorRed/lotteryAddressList.vue'),
    meta: { title: '选择邮寄地址' },
  },
  {
    path: '/activity/lotteryAddressAdd',
    name: 'activityLotteryAddressAdd',
    component: () => import('@/views/24openDoorRed/lotteryAddressAdd.vue'),
    meta: { title: '新增地址' },
  },
  {
    path: '/activity/chooseGear',
    name: 'activityChooseGear',
    component: () => import('@/views/24openDoorRed/chooseGear.vue'),
    meta: { title: '我要选档' },
  },
  {
    path: '/activity/customerList',
    name: 'activityCustomerList',
    component: () => import('@/views/24openDoorRed/customerList.vue'),
    meta: { title: '上账明细' },
  },
  {
    path: '/activity/upApplication',
    name: 'activityUpApplication',
    component: () => import('@/views/24openDoorRed/upApplication.vue'),
    meta: { title: '客户信息' },
  },
  {
    path: '/activity/recharge',
    name: 'activityRecharge',
    component: () => import('@/views/24openDoorRed/components/recharge.vue'),
    meta: { title: '我要充值' },
  },
  {
    path: '/activity/middlePage',
    name: 'activityMiddlePage',
    component: () => import('@/views/24openDoorRed/components/middlePage.vue'),
    meta: { title: '旅游报名' },
  },
  {
    path: '/activity/rechargeInvite',
    name: 'rechargeInvite',
    component: () => import('@/views/activities/rechargeInvite.vue'),
    meta: { title: '邀请注册' },
  },
  {
    path: '/activity/branchInvite',
    name: 'branchInvite',
    component: () => import('@/views/activities/branchInvite.vue'),
    meta: { title: '分享注册' },
  },
  {
    path: '/activity/inviteRecharge',
    name: 'inviteRecharge',
    component: () => import('@/views/activities/inviteRecharge.vue'),
    meta: { title: '充值' },
  },
]
