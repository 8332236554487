import { post, get } from '@/service/request'

// 用户名密码登录
export const getAvailableBanners = (custLevel) => {
  return post(`/goods/banners/h5/available?custLevel=${custLevel}`)
}

export const getRecommendGoods = ({ page = 0, pageSize = 10 }) => {
  return get('/goods/h5/recommend/page', { page, pageSize, saleAccType: 0 })
}

export const getCityYH = (custCode) => {
  return get(`/customer/h5/cust/customer/getCityYH/${custCode}`)
}

export const getNewGoods = (param) => {
  return post(`/goods/h5/goods/getNewGoods`, param)
}
