/**
 * 1.首先判断设备是否为IPx
 * 因为ipx的设备为流海屏  垂直高度大于812
 */

const isIponeX = () => {
  if (typeof window !== 'undefined' && window) {
    //window.navigator.userAgent 如果它包含ipone  表示当前的设备为 ipone.
    // iponex的设备  垂直的高度大于812的像素
    //return /iphone/gi.test(window.navigator.userAgent) &&   window.screen.height >= 812
    if (
      /iphone/gi.test(window.navigator.userAgent) &&
      window.screen.width === 414
    ) {
      localStorage.setItem('isIponeX', true)
    } else {
      localStorage.setItem('isIponeX', false)
    }
  }
}

// 计算结果   赋值给 window 对象的一个属性
window.isIponeX = isIponeX()
