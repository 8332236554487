import { get, post, put } from '@/service/request'

export const qyCustomerPage = (data) =>
  post('/quanyu/dms/h5/member/agentMemberPage', data)

export const searchPrice = ({ objQuery, page, size }) =>
  post('/goods/h5/goods/searchPrice', {
    objQuery,
    page,
    size,
  })

export const qySelectGoodCart = (params) =>
  get(`/quanyu/h5/good/search/selectGoodCart`, params)

export const checkQyOrderLimit = (params) =>
  post(`/quanyu/h5/order/check/saleLimit`, params)

export const getMemberAddress = (params) =>
  get(`/quanyu/dms/h5/member/getMemberAddress`, params)

export const qyCreateOrder = (params) =>
  post(`/quanyu/h5/order/createOrder`, params)

//注册-校验手机号是否存在
export const checkPhone = (params) =>
  get(`/quanyu/dms/h5/member/checkPhone`, params)
export const smsCode = (params) => get(`/quanyu/dms/h5/member/sms/code`, params)

export const qyRegister = (params) =>
  post(`/quanyu/dms/h5/member/register`, params)

// 根据代理编码查询门店数据
export const storeSelectByCustomerCode = (params) =>
  get(`/quanyu/h5/store/search/selectByCustomerCode/${params.customerCode}`, {})

// 待确认收货订单数量
export const orderWaitConfirm = (params) =>
  get(`/quanyu/h5/purchase/order/waitConfirm/${params.storeNumber}`, {})

// 根据门店查询有库存的商品数据
export const selectGoodLocalList = (data) =>
  post(`/quanyu/h5/good/search/selectGoodLocalList`, data)

export const outCheckCode = (params) =>
  get(`/quanyu/h5/product/code/out/checkCode/${params.wmStoreId}`, params)

// 读取url中的溯源码
export const getTraceCode = (params) =>
  get(`/quanyu/h5/product/code/url/code`, params)

// 码值无出库记录-历史库存入库
export const codeSaveHistoryInStock = (data) =>
  post(`/quanyu/h5/product/code/saveHistoryInStock`, data)

// 根据门店和商品品69码查询商品数据
export const selectGoodLocalByBarCode = (params) =>
  get(
    `/quanyu/h5/good/search/selectGoodByBarcode/${params.storeId}/${params.barcode}`,
    params
  )

// 分页获取销售订单列表信息
export const orderPage = (data) => post(`/quanyu/h5/order/page`, data)

// 转单
export const orderTransfer = (data) =>
  post(`/quanyu/h5/order/transfer/${data.orderNo}`, data)

// 取消订单
export const orderCancel = (data) => post(`/quanyu/h5/order/cancelOrder`, data)

// 订单隐藏
export const orderHide = (data) =>
  put(`/quanyu/h5/order/hidden/${data.orderNo}`, data)

// 查询销售订单明细
export const orderDetail = (params) =>
  get(`/quanyu/h5/order/detail/${params.orderNo}`, params)

// 新增会员收货地址
export const addMemberAddress = (data) =>
  post(`/quanyu/dms/h5/member/addMemberAddress`, data)

// 出库单分页查询
export const outPage = (data) => post(`/quanyu/h5/purchase/order/page`, data)

// 根据ID查询出库单明细
export const outDetail = (params) =>
  get(`/quanyu/h5/purchase/order/${params.deliverNo}`, params)

// [分公司模式]批量查询钱包余额
export const batchQueryBalance = (data) =>
  post(`/dcw/bcompany/h5/customWallet/batchGetBalanceByQuery`, data)

// 确认全部入库
export const confirmAllInStock = (data) =>
  post(`/quanyu/h5/purchase/order/allWarehouse?id=${data.id}`, data)

// 确认部分收货
export const confirmPartInStock = (data) =>
  post(`/quanyu/h5/purchase/order/warehouse`, data)

export const getWxConfig = (params) => get(`/admin/user/wx/js/config`, params)

export const getQyWxConfig = (params) =>
  get(`/admin/qw/third/js/config`, params)

export const posterGenerate = (data) =>
  post(`/quanyu/h5/vip/register/poster/generate`, data)

export const searchStockPage = (data) =>
  post(`/quanyu/h5/good/search/stock/page`, data)

export const stockFlow = (data) => post(`/quanyu/h5/stock/order/records`, data)
