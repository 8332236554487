import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vant from 'vant'
import './styles/common.less'
import './newStyles/index.less'
import 'vant/lib/index.css'
import 'lib-flexible'
import '@/utils/isIponex.js'
import './utils/direcFun'
import * as echarts from 'echarts'
import '@/assets/font/font.css'
import { isWeChat, isWorkWechat } from '@/utils/utils'
import '@/newStyles/confirm'

let el = {}
if (isWorkWechat()) {
  el = require('@/utils/wxworkShare.js')
  el.createWxworkEle()
} else if (isWeChat()) {
  el = require('@/utils/wxShare.js')
  el.createWxEle()
}

Vue.prototype.$commonShare = (shareMes) => {
  if (isWorkWechat()) {
    el.wxwork_init(shareMes)
  } else if (isWeChat()) {
    el.getWxConfigFun(shareMes)
  }
}

Vue.use(Vant)
Vue.prototype.$echarts = echarts
Vue.prototype.$bus = new Vue()
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
