import axios from 'axios'
import { setStore } from '@/utils/store'

export const initScript = async () => {
  const html = await getHtml()
  setStore({ name: 'oldScript', content: parserScript(html), type: 1 })
}

export const getHtml = async () => {
  return await axios('/').then((res) => res.data)
}

export const parserScript = (html) => {
  const reg = new RegExp(/<script(?:\s+[^>]*)?>(.*?)<\/script\s*>/gi)
  return html.match(reg)
}

export const compareScript = (oldArr, newArr) => {
  const base = oldArr.length
  const arr = Array.from(new Set(oldArr.concat(newArr)))
  // 如果新旧length 一样无更新
  return arr.length !== base
}
