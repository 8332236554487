<template>
  <div class="banner-container">
    <van-swipe :autoplay="5000">
      <van-swipe-item v-for="({ link, picMin }, index) in banners" :key="index">
        <a href="javascript:void(0)" @click="onClick(link)">
          <van-image :src="picMin" width="100%" height="100%" />
        </a>
      </van-swipe-item>
    </van-swipe>
  </div>
</template>

<script>
import { getAvailableBanners } from './api/home.api'
import { mapGetters } from 'vuex'

export default {
  name: 'Banner',
  components: {},
  data() {
    return {
      banners: [],
    }
  },
  computed: {
    ...mapGetters(['customerInfo', 'currentSelectRole', 'saleMode']),
  },
  mounted() {
    this.getBanner()
  },
  methods: {
    onClick(link) {
      this.$router.push(link)
    },
    getBanner() {
      getAvailableBanners(
        this.saleMode
          ? this.currentSelectRole.companyPartnerType
          : this.customerInfo.custLevel
      )
        .then((res) => {
          if (res.respCode === '10000') {
            this.banners = res.respData
          }
        })
        .catch((reason) => (this.banners = []))
    },
  },
}
</script>

<style lang="less" scoped>
@border-radius: 6px;

.banner-container {
  position: relative;
  .search-input {
    position: absolute;
    top: 12px;
    left: 12px;
    right: 12px;
    z-index: 999;
    width: auto;
  }
  /deep/ .van-swipe {
    height: 145px;
    border-radius: 9px;
  }
}

/deep/ .van-swipe__indicator {
  width: 10px;
  height: 6px;
  border-radius: 3px;
  background-color: @black;
  opacity: 0.2;
}

/deep/ .van-swipe__indicator--active {
  width: 20px;
  border-radius: 3px;
  background-color: @white;
  opacity: 1;
}
</style>
