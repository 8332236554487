import { getQyWxConfig } from '@/views/qyOrder/model'

export const createWxworkEle = () => {
  window.wx = null
  window.jWeixin = null
  if (document.getElementById('wxworkScriptId')) {
    removewxwork()
  }
  appendwxwork()
}

export const wxwork_init = async (params) => {
  // {title, linkUrl, sharePic, sortTitle}
  const res = await getAppId()
  console.log('getAppId', res)
  wxwork_setConfig(res, params)
}

async function getAppId() {
  const url = window.location.href.split('#')[0]
  const config = await getQyWxConfig({ url })
  console.log('config', config)
  return config.data
}

function wxwork_setConfig({ appId, timestamp, nonceStr, signature }, params) {
  const jsApiList = [
    'onMenuShareAppMessage',
    'onMenuShareWechat',
    'onMenuShareTimeline',
  ]
  window.wx.config({
    beta: true, // 必须这么写，否则wx.invoke调用形式的jsapi会有问题
    debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
    appId, // 必填，企业微信的corpID
    timestamp, // 必填，生成签名的时间戳
    nonceStr, // 必填，生成签名的随机串
    signature, // 必填，签名，见 附录-JS-SDK使用权限签名算法
    jsApiList, // 必填，需要使用的JS接口列表，凡是要调用的接口都需要传进来
  })
  window.wx.checkJsApi({
    jsApiList, // 需要检测的JS接口列表
    success: (res) => {
      console.log('checkJsApi success', res)
      // 以键值对的形式返回，可用的api值true，不可用为false
      // 如：{"checkResult":{"chooseImage":true},"errMsg":"checkJsApi:ok"}
    },
  })
  window.wx.ready((r) => {
    console.log('rrr', r)
    wxwork_onMenuShareAppMessage(params)
    wxwork_onMenuShareWechat(params)
    wxwork_onMenuShareTimeline(params)
  })
  window.wx.error((e) => {
    console.log('eee', e)
  })
}
// 转发
function wxwork_onMenuShareAppMessage({ title, desc, link, imgUrl }) {
  window.wx.onMenuShareAppMessage({
    title, // 分享标题
    desc, // 分享描述
    link, // 分享链接；在微信上分享时，该链接的域名必须与企业某个应用的可信域名一致
    imgUrl, // 分享图标
    // enableIdTrans: 1, // 是否开启id转译，不填默认为0
  })
}
// 分享到微信
function wxwork_onMenuShareWechat({ title, desc, link, imgUrl }) {
  window.wx.onMenuShareWechat({
    title, // 分享标题
    desc, // 分享描述
    link, // 分享链接
    imgUrl, // 分享图标
    // enableIdTrans: 1, // 是否开启id转译，不填默认为0
  })
}
// 分享到朋友圈
function wxwork_onMenuShareTimeline({ title, link, imgUrl }) {
  window.wx.onMenuShareTimeline({
    title, // 分享标题
    link, // 分享链接；在微信上分享时，该链接的域名必须与企业某个应用的可信域名一致
    imgUrl, // 分享图标
    // enableIdTrans: 1, // 是否开启id转译，不填默认为0
  })
}

function appendwxwork() {
  //
  const ele = document.createElement('script')
  ele.type = 'text/javascript'
  ele.src = 'https://res.wx.qq.com/open/js/jweixin-1.2.0.js'
  ele.id = 'wxworkScriptId'
  document.body.appendChild(ele)
}
function removewxwork() {
  const ele = document.getElementById('wxworkScriptId')
  document.body.removeChild(ele)
}
