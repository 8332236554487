import { getStore, setStore, removeStore } from '@/utils/store'

const behalfCart = {
  state: {
    list: getStore({ name: 'behalf-cart' }) || [],
    memberInfo: getStore({ name: 'member-info' }) || {},
    storeInfo: getStore({ name: 'store-info' }) || {},
  },
  getters: {
    behalfCartList: (state) => state.list,
    behalfCartCount: (state) => state.list.length,
    behalfCartTotalCount: (state) =>
      state.list.reduce((total, item) => total + item.skuCount, 0), // 总数量
    behalfCartTotalPrice: (state) =>
      state.list.reduce(
        (total, item) =>
          total + Number((item.skuCount * item.price).toFixed(2)),
        0
      ), // 总价格
    afterTotalPrice: (state) =>
      state.list.reduce(
        (total, item) =>
          total + Number((item.skuCount * item.afterPrice).toFixed(2)),
        0
      ),
    memberTotalPrice: (state) =>
      state.list.reduce(
        (total, item) =>
          total + Number((item.skuCount * item.priceMember).toFixed(2)),
        0
      ),
    memberInfo: (state) => state.memberInfo,
    storeInfo: (state) => state.storeInfo,
    localCodeList: (state) =>
      state.list.reduce((pre, cur) => {
        if (cur.wmOrderProductCodeVos) {
          return [...pre, ...cur.wmOrderProductCodeVos.map((i) => i.smallCode)]
        }
      }, []) || [],
  },
  mutations: {
    SET_MEMBER: (state, member) => {
      state.memberInfo = member
      setStore({
        name: 'member-info',
        content: member,
        type: 'session',
      })
    },
    REMOVE_MEMBER: (state) => {
      state.memberInfo = {}
      removeStore({
        name: 'member-info',
        type: 'session',
      })
    },
    SET_STORE: (state, store) => {
      state.storeInfo = store
      setStore({
        name: 'store-info',
        content: store,
        type: 'session',
      })
    },
    REMOVE_STORE: (state) => {
      state.storeInfo = {}
      removeStore({
        name: 'store-info',
        type: 'session',
      })
    },
    //保存购物车
    SET_BEHALF_CART: (state, goods) => {
      state.list = [goods, ...state.list]
      setStore({
        name: 'behalf-cart',
        content: state.list,
        type: 'session',
      })
    },
    //删除某个购物车
    DELETE_BEHALF_CART: (state, goods) => {
      state.list = state.list.filter(
        (item) =>
          item.id + item.code + item.customWallet + item.pickUpCode !==
          goods.id + goods.code + goods.customWallet + goods.pickUpCode
      )
      setStore({
        name: 'behalf-cart',
        content: state.list,
        type: 'session',
      })
    },
    //删除某个购物车
    DELETE_MULTI_BEHALF_CART: (state, list) => {
      state.list = state.list.filter(
        (item) =>
          list
            .map((i) => i.id + i.code + i.customWallet + i.pickUpCode)
            .indexOf(
              item.id + item.code + item.customWallet + item.pickUpCode
            ) === -1
      )
      setStore({
        name: 'behalf-cart',
        content: state.list,
        type: 'session',
      })
    },
    //修改某个购物车
    UPDATE_BEHALF_CART: (state, goods) => {
      state.list.splice(goods.index, 1, goods)

      const arr = []
      state.list.forEach((item, index) => {
        if (
          item.id + item.code + item.customWallet + item.pickUpCode ===
          goods.id + goods.code + goods.customWallet + goods.pickUpCode
        )
          arr.push(index)
      })
      if (arr.length > 1) {
        const skuCount = state.list
          .filter(
            (item) =>
              item.id + item.code + item.customWallet + item.pickUpCode ===
              goods.id + goods.code + goods.customWallet + goods.pickUpCode
          )
          .reduce((pre, cur) => pre + cur.skuCount, 0)
        const obj = state.list[arr[0]]
        obj.skuCount = skuCount
        state.list.splice(arr[0], 1, obj)
        state.list.splice(arr[1], 1)
      }
      setStore({
        name: 'behalf-cart',
        content: state.list,
        type: 'session',
      })
    },
    //清除购物车
    REMOVE_BEHALF_CART: (state) => {
      state.list = []
      removeStore({
        name: 'behalf-cart',
        type: 'session',
      })
    },
  },
  actions: {
    //添加购物车
    addBehalfCart({ commit, state }, goods) {
      const hasGoods = state.list.some(
        (item) =>
          item.id + item.code + item.customWallet + item.pickUpCode ===
          goods.id + goods.code + goods.customWallet + goods.pickUpCode
      )
      if (hasGoods || goods.index !== undefined) {
        commit('UPDATE_BEHALF_CART', goods)
        return
      }
      commit('SET_BEHALF_CART', goods)
    },
    //删除某个购物车
    deleteBehalfCart({ commit }, goods) {
      commit('DELETE_BEHALF_CART', goods)
    },
    deleteMultiBehalfCart({ commit }, list) {
      commit('DELETE_MULTI_BEHALF_CART', list)
    },
    //修改某个购物车
    updateBehalfCart({ commit }, goods) {
      commit('UPDATE_BEHALF_CART', goods)
    },
    //清除购物车
    removeBehalfCart({ commit }) {
      commit('REMOVE_BEHALF_CART')
    },
    setMember({ commit }, member) {
      commit('SET_MEMBER', member)
    },
    removeMember({ commit }) {
      commit('REMOVE_MEMBER')
    },
    setStore({ commit }, store) {
      commit('SET_STORE', store)
    },
    removeStore({ commit }) {
      commit('REMOVE_STORE')
    },
  },
}
export default behalfCart
