import { get, post } from '@/service/request'

// 查询购物车
export const shoppingCartApi = (params) =>
  post(`/goods/h5/shopping-cartV1`, params)

export const selectGoodCustomWallet = (params) =>
  get(`/quanyu/h5/good/search/selectGoodCustomWallet`, params, {})

export const shoppingCartApiV2 = (params) =>
  post(`/goods/h5/shopping-cartV2`, params)
