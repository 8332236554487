import axios from 'axios'
import { Toast } from 'vant'
import errorCode from '@/const/errorCode'
import { serialize } from '@/utils/utils'
import { getStore, clearStore } from '@/utils/store'
import qs from 'qs'
import router from '@/router'
import store from '@/store/'
import { TOKEN } from '@/const/storeConst'
import {
  SaleChannelEnum,
  SaleChannelKey,
  SaleModeEnum,
  SaleModeKey,
} from '@/const/common'
import _ from 'lodash'

const request = axios.create()
request.defaults.timeout = 50000 // axios请求超时时间
request.defaults.withCredentials = true

request.defaults.validateStatus = function (status) {
  return status >= 200 && status <= 500 // 默认的
}

//request.defaults.headers['Content-Type'] = 'application/json' // axios发送数 据时使用json格式
//request.defaults.transformRequest = (data) => JSON.stringify(data) // 发送数据前进 行json格式化

// 第二：设置拦截器
/**
 ** 请求拦截器(当前端发送请求给后端前进行拦截)
 *例1：请求拦截器获取token设置到axios请求头中，所有请求接口都具有这个功能
 *例2：到用户访问某一个页面，但是用户没有登录，前端页面自动跳转 /login/ 页面
 */
// 不添加渠道参数白名单
const whileList = ['/auth/oauth/token']

export function saleChannel() {
  // return 'cloud'
  try {
    // 按照当前选的模式来返回saleChannel
    return SaleChannelEnum[getStore({ name: 'currentSelectMode' })]
  } catch (e) {
    console.error('当前没有 currentSelectMode 字段')
  }
}
export function saleMode() {
  // return 'cloud'
  try {
    // 按照当前选的模式来返回saleMode
    return SaleModeEnum[getStore({ name: 'currentSelectMode' })]
  } catch (e) {
    console.error('当前没有 currentSelectMode 字段')
  }
}
// 获取当前模式 saleModeChannel
export function saleModeChannel() {
  return saleMode() + saleChannel()
}

request.interceptors.request.use(
  (config) => {
    //  console.log('config', config)
    // 从localStorage中获取token
    // let token = localStorage.getItem('token');
    // 如果有token, 就把token设置到请求头中Authorization字段中
    // token && (config.headers.Authorization = token);
    Toast.loading({
      message: '加载中',
      forbidClick: true,
      loadingType: 'spinner',
      duration: 0,
    })

    const TENANT_ID = getStore({ name: 'tenantId' })
    const isToken = (config.headers || {}).isToken === false
    const token = store.getters.access_token
    if (token && !isToken && !config.headers['ignoreToken']) {
      config.headers['Authorization'] = 'Bearer ' + token // token
    }
    if (TENANT_ID) {
      config.headers['TENANT-ID'] = TENANT_ID // 租户ID
    }
    config.headers['PROGRAM-CODE'] = 'DMS'
    if (
      !whileList.includes(config.url) &&
      !config.headers['ignoreSaleChannel']
    ) {
      // 增加一人一城销售渠道配置

      config.headers[SaleChannelKey] = saleChannel()
      config.headers[SaleModeKey] = saleMode()

      if (!_.isEmpty(config.data) && !Array.isArray(config.data)) {
        // 如果有分页查询条件 将saleChannel、saleMode加到objQuery里
        if (config.data.objQuery) {
          config.data.objQuery = {
            ...config.data.objQuery,
            saleChannel: saleChannel(),
            saleMode: saleMode(),
          }
        }
        config.data = {
          ...config.data,
          saleChannel: saleChannel(),
          saleMode: saleMode(),
        }
      }
      if (!_.isEmpty(config.params) && !Array.isArray(config.params)) {
        config.params = {
          ...config.params,
          saleChannel: saleChannel(),
          saleMode: saleMode(),
        }
      }
    }
    // headers中配置serialize为true开启序列化
    if (config.method === 'post' && config.headers.serialize) {
      config.data = serialize(config.data)
      delete config.data.serialize
    }

    if (config.method === 'get') {
      config.paramsSerializer = (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      }
    }

    if (config.method === 'put') {
      config.paramsSerializer = (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      }
    }

    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)
/**
 * 响应拦截器（当后端返回数据的时候进行拦截）
 * 例1：当后端返回状态码是401/403时，跳转到 /login/ 页面
 */

request.interceptors.response.use(
  (res) => {
    //  console.log('typeof', res.config)
    if (res.config.data !== undefined) {
      let loading = null
      if (typeof res.config.data === 'string') {
        if (res.config.data.indexOf('{') === -1) {
          Toast.clear()
        } else {
          loading = JSON.parse(res.config.data).loading
        }
      } else if (res.config.data instanceof FormData) {
        loading = res.config.data.get('loading')
      } else {
        loading = res.config.data.loading
      }
      if (loading === false || loading === 'false') {
        // 你关闭loading
      } else {
        Toast.clear()
      }
    } else {
      Toast.clear()
    }
    // console.log('res', res)
    // 当响应码是 2xx 的情况, 进入这里
    // debugger

    const status = Number(res.status) || 200
    const message =
      res.data.msg ||
      res.data.respDesc ||
      errorCode[status] ||
      errorCode['default']

    if (status === 401) {
      Toast.clear()
      Toast.fail(message)
      if (message === '用户凭证已过期') {
        // 清理缓存，重新登录
        sessionStorage.clear()
        clearStore()
        store.commit('SET_ACCESS_TOKEN', null)
        const timeOut = setTimeout(() => {
          this.$router.push({ path: '/wx-login' })
          // router.replace({ path: '/login' })
          clearTimeout(timeOut)
        })
      }
      return
    }
    // 403登录超时
    if (status === 403) {
      Toast.clear()
      Toast.fail('登录超时，请重新登录！')
      sessionStorage.removeItem(TOKEN)
      store.commit(TOKEN, null)
      const timeOut = setTimeout(() => {
        router.replace({ name: 'wx-login' })
        clearTimeout(timeOut)
      })
      return
    }

    if (
      status !== 200 ||
      res.data.code === 1 ||
      res.data.respCode === '99999'
    ) {
      if (res.data.msg === 'ok') return res.data
      if (
        res.config.data !== undefined &&
        typeof res.config.data === 'string' &&
        res.config.data.indexOf('{') !== -1
      ) {
        const data = JSON.parse(res.config.data).returnData
        if (data || data === 'true') {
          return res.data
        }
      }
      Toast('出错了!' + message)
      Promise.reject(new Error(message))
      return res.data
    }
    return res.data
  },
  (error) => {
    // 当响应码不是 2xx 的情况, 进入这里
    Toast.clear()
    return Promise.reject(new Error(error))
  }
)

/**
 * get方法，对应get请求
 * @param {String} url
 * @param {Object} params
 * @param {Object} headers
 * */

export function get(url, params, headers = {}) {
  return new Promise((resolve, reject) => {
    request
      .get(url, { params, headers })
      .then((res) => {
        resolve(res)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

// 第三：根据上面分装好的axios对象，封装 get、post、put、delete请求
/**
 * post方法，对应post请求
 * @param {String} url
 * @param {Object} params
 * @param {Object} headers
 **/

export function post(url, params, headers = {}) {
  return new Promise((resolve, reject) => {
    request
      .post(url, params, headers)
      .then((res) => {
        resolve(res)
      })
      .catch((err) => {
        // debugger
        reject(err)
      })
  })
}

export function put(url, params, headers = {}) {
  return new Promise((resolve, reject) => {
    request
      .put(url, params, headers)
      .then((res) => {
        resolve(res)
      })
      .catch((err) => {
        // debugger
        reject(err)
      })
  })
}

export function del(url, params, headers = {}) {
  return new Promise((resolve, reject) => {
    request
      .delete(url, { data: params, headers })
      .then((res) => {
        resolve(res)
      })
      .catch((err) => {
        // debugger
        reject(err)
      })
  })
}

export default request
