<script>
export default {
  name: 'comb-sku',
  props: {
    visible: {
      default: false,
      type: Boolean,
    },
    skus: {
      default: () => [],
      type: Array,
    },
  },
  methods: {
    getTagList(item) {
      const tagList = []
      if (item.isAdvent === 'Y') tagList.push('临期')
      if (item.isPresale === 'Y') tagList.push('预售')
      return tagList
    },
  },
}
</script>

<template>
  <van-popup
    :value="visible"
    closeable
    class="goods-list-pop font-14"
    @click-overlay="$emit('closeComb')"
    @click-close-icon="$emit('closeComb')"
    @close="$emit('closeComb')"
    :style="{ 'max-height': '65%', height: 'auto', width: '80%' }"
    get-container="body"
  >
    <div class="">
      <div class="font-16 main-color-title mb-3 t-center font-weight-500">
        组合商品
      </div>
      <div class="main-color-order-static">该组合商品包含以下商品</div>
      <div
        class="d-flex limit-item mt-2"
        v-for="(item, index) in skus"
        :key="index"
      >
        <img :src="item.coverImage" alt="" />
        <div class="flex-1 d-flex flex-column j-sb">
          <div>
            <van-tag
              v-for="(tag, tagIndex) in getTagList(item)"
              :key="tagIndex"
              color="#FE3245"
              class="mr"
            >
              {{ tag }}
            </van-tag>
            {{ item.skuName }}
          </div>
          <div
            class="font-12 main-color-liary"
            v-if="item.adventTime && item.isAdvent === 'Y'"
          >
            {{ item.adventTime }}到期
          </div>
          <div
            class="font-12 main-color-liary"
            v-if="item.presaleDeliveryTime && item.isPresale === 'Y'"
          >
            {{ item.presaleDeliveryTime }}发货
          </div>
          <div class="d-flex j-sb a-center font-12">
            <div class="main-color-order-static"></div>
            <div class="main-color-liary">x{{ item.skuCount }}</div>
          </div>
        </div>
      </div>
    </div>
  </van-popup>
</template>

<style scoped lang="less">
.goods-list-pop {
  border-radius: 10px;
  height: 100%;
  padding: 18px 16px;
  .limit-item {
    img {
      width: 70px;
      height: 70px;
      border-radius: 4px;
      margin-right: 10px;
    }
  }
}
</style>
