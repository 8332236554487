<!--
加入购物车
-->
<template>
  <van-popup
    v-model="cartVisible"
    round
    position="bottom"
    get-container=".goods-wrapper"
    class="account-type-pop"
    :style="{ height: '65%', background: step === 2 && '#f6f6f6' }"
    @close="submitChoose(false)"
  >
    <div class="cart-container" v-if="step === 1">
      <div class="cart-title">
        {{ activeType === 'cart' ? '加入购物车' : '去购买' }}
        <van-image
          class="icon-close"
          @click="submitChoose(false)"
          :src="require('@/assets/home/close.svg')"
        ></van-image>
      </div>
      <div class="cart-content">
        <div class="goods-item">
          <div class="image-container">
            <van-image
              class="goods-img"
              :radius="12"
              :src="coverImage"
            ></van-image>
            <img
              class="comb-icon"
              src="@/assets/qyOrder/comb-icon.png"
              alt=""
              v-if="goodItem.skuFlag === 'C'"
              @click="showComb"
            />
          </div>
          <div class="goods-info">
            <div class="title-info-container">
              <van-tag
                v-for="(tag, tagIndex) in getTagList"
                :key="tagIndex"
                color="#FE3245"
                class="mr"
              >
                {{ tag }}
              </van-tag>
              <span class="goods-title">{{ goodItem.skuName }}</span>
            </div>
            <div
              class="font-12 main-color-liary"
              v-if="goodItem.adventTime && goodItem.isAdvent === 'Y'"
            >
              {{ goodItem.adventTime }}到期
            </div>
            <div
              class="font-12 main-color-liary"
              v-if="goodItem.presaleDeliveryTime && goodItem.isPresale === 'Y'"
            >
              {{ goodItem.presaleDeliveryTime }}发货
            </div>
            <div class="price-container">
              <price-text
                :price="goodItem.price || goodItem.goodsPrice"
              ></price-text>
            </div>
          </div>
        </div>
        <div class="buy-container">
          <span>
            购买数量
            <span class="buy-limit" v-if="limit && limit.limit">
              {{ '(' + limit.text + ')' }}
            </span>
          </span>
          <van-stepper
            v-model="count"
            integer
            class="ns-stepper stepper"
            :step="Number(goodItem.saleMinOrder || 1)"
            :min="Number(goodItem.saleMinOrder || 1)"
            :max="limit && !limit.period ? limit.limit : undefined"
            @blur="changeCount()"
            @overlimit="overLimit"
          />
        </div>
      </div>
    </div>
    <div class="cart-container" v-if="step === 2">
      <div class="cart-title" style="margin-bottom: 0">
        选择扣款方式
        <img
          class="icon-close"
          @click="submitChoose(false)"
          src="@/assets/home/close.svg"
          alt=""
        />
      </div>
      <div class="mt-12"></div>
      <div class="font-14 main-color-liary t-center">
        <div
          class="main-color-title mt-12 d-flex a-item-baseline j-center font-23"
        >
          ￥
          <span
            class="font-weight font-DIN-Alternate-Bold"
            style="font-size: 42px"
          >
            {{ agentPrice }}
          </span>
        </div>
        <div class="main-color-adress">
          单价: ￥{{ radio.price || goodItem.price }}
        </div>
        <div class="font-12 mt-1 wallet-tip">
          当前位置展示的扣款金额会根据选择的钱包不同，同步变动
          <van-icon name="question" color="#999" @click="walletTips = true" />
          <div class="wallet-tip-content font-12" v-if="walletTips">
            <div class="d-flex j-sb">
              混拿货款，邮费(货补)：扣代理价
              <van-icon name="cross" color="#fff" @click="walletTips = false" />
            </div>
            <div class="mt-1">零售价赠送款、物料赠送款：扣零售价</div>
            <div class="mt-1">特价款：根据特价活动配置的价格进行扣款</div>
            <div class="mt-1">提货券：根据券对应的钱包类型扣款</div>
          </div>
        </div>
      </div>
      <div class="mt-12"></div>
      <div>
        <van-radio-group v-model="radio">
          <div
            class="main-border-6 d-flex a-center wallet-item mt-12 bg-white main-border-6 p-24 mx-2"
            v-for="(item, index) in walletList"
            :key="index"
          >
            <img
              :src="
                require(`@/assets/goods/${
                  item.pickUpCode ? 'PICKUP' : item.customWallet
                }.png`)
              "
              alt=""
            />
            <div class="ml-2">
              <div class="d-flex">
                <div class="font-14">
                  {{ item.walletName }}
                </div>
                <div class="font-12 main-color-liary">
                  (余额:￥{{ item.balance }})
                </div>
              </div>
              <div
                class="font-12 main-color-liary"
                v-if="item.status === 'finished'"
              >
                提货券已过期,无法使用
              </div>
            </div>

            <div class="ml-auto">
              <van-radio
                class="ns-radio"
                :disabled="item.status === 'finished'"
                :name="item"
              />
            </div>
          </div>
        </van-radio-group>
      </div>
    </div>
    <div
      class="bottom-button-container"
      :style="{ background: step === 2 && '#f6f6f6', border: 'none' }"
      v-if="step === 2"
    >
      <van-button
        round
        block
        class="btn-large qy-active-button"
        @click="submitChoose(true)"
      >
        {{ activeType === 'cart' ? '加入购物车' : '立即购买' }}
      </van-button>
    </div>
    <div
      class="tips-bottom"
      :style="{ background: step === 2 && '#f6f6f6' }"
      v-if="step === 1 && walletList.length"
    >
      <div class="font-12 main-color-liary t-center">
        选择商品后需选择当前商品的扣款方式
      </div>
      <div class="d-flex j-sb a-center p-24 pb-3">
        <van-button
          round
          block
          class="btn-large qy-active-button"
          @click="step = 2"
        >
          选择扣款方式
        </van-button>
      </div>
    </div>
    <div
      class="tips-bottom"
      v-if="step === 1 && !walletList.length && !stock.pickUpStartTime"
    >
      <div class="tips p-24 mb-3">
        抱歉,当前商品暂无可使用的扣款方式,如需下单发货,请联系客服处理~
      </div>
    </div>
    <div class="tips-bottom" v-if="step === 1 && stock.pickUpStartTime">
      <div class="d-flex j-center">
        <div class="count-down">
          <van-count-down
            class="time"
            format="HH时mm分ss秒"
            :time="stock.pickUpStartTime"
          />
        </div>
      </div>
      <div class="tips p-24 mt-12 mb-3">
        抱歉，由于运营活动限制，当前商品暂时无法下单，待活动开始后，方可正常下单
      </div>
    </div>
    <comb-sku
      :visible="combVisible"
      :skus="combSkus"
      @closeComb="combVisible = false"
    />
  </van-popup>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  selectGoodCustomWallet,
  shoppingCartApiV2,
} from '@/views/shoppingCart/apis'
import { SaleChannelTypesConst } from '@/const/common'
import { Toast } from 'vant'
import { getLimitInfo } from '@/utils/utils'
import PriceText from '@/components/price-text.vue'
import CombSku from '@/views/qyOrder/components/comb-sku.vue'

export default {
  name: 'addToCart',
  components: { CombSku, PriceText },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    goodItem: {
      type: Object,
      default: () => {},
    },
    //账户类型
    saleAccType: {
      type: String,
      default: '',
    },
    //cart 加入购物车    order 下单
    activeType: {
      type: String,
      default: 'cart',
    },
    //封面
    coverImage: {
      type: String,
      default: '',
    },
    saleChannel: {
      type: String,
      default: SaleChannelTypesConst.cloud,
    },
    changeWallet: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      cartVisible: this.visible,
      count: 1,
      combVisible: false,
      combSkus: [],
      stock: {},
      step: 1,
      walletList: [],
      radio: {},
      walletTips: false,
    }
  },
  computed: {
    ...mapGetters(['getUnitsList', 'currentSelectRole']),
    limit() {
      return getLimitInfo(this.goodItem)
    },
    getTagList() {
      const tagList = []
      if (this.goodItem.isAdvent === 'Y') tagList.push('临期')
      if (this.goodItem.isPresale === 'Y') tagList.push('预售')
      return tagList
    },
    agentPrice() {
      return Number(
        (this.count * (this.radio.price || this.goodItem.price)).toFixed(2)
      )
    },
  },
  mounted() {
    this.getUnitDict()
    this.getLimit()
  },
  methods: {
    //获取字典
    async getUnitDict() {
      if (this.getUnitsList) return
      await this.$store.dispatch('UnitType')
    },
    async getLimit() {
      const params = {
        customSubjectCode: this.currentSelectRole.creditCard,
        goodCode: this.goodItem.skuCode || this.goodItem.outerSkuCode,
        customLevel: this.currentSelectRole.companyPartnerType,
      }
      const res = await selectGoodCustomWallet(params)
      this.stock = res.data
      if (this.stock.walletList) {
        this.walletList = this.stock.walletList.filter(
          (i) => i.status !== 'to_start'
        )
      }
      if (this.changeWallet) {
        this.step = 2
      }
      if (this.goodItem.skuCount) this.count = this.goodItem.skuCount
    },
    submitChoose(val) {
      if (val) {
        this.submit()
      } else {
        this.$emit('back')
      }
    },
    //手动输入数量校验
    changeCount() {
      // @blur='changeCount()'
      if (Number(this.goodItem.saleMinOrder)) {
        this.count =
          Math.round(this.count / this.goodItem.saleMinOrder) *
          this.goodItem.saleMinOrder
      }
    },
    overLimit(event) {
      if (event === 'plus') {
        Toast(`已达到限购数量，不允许再次添加`)
      }
    },
    async submit() {
      if (!this.radio.customWallet) return Toast('请选择扣款方式')
      const params = {
        custCode: this.currentSelectRole.custCode,
        custSubjectCode: this.currentSelectRole.creditCard,
        operate: this.goodItem.cacheUniqueId ? 'change' : 'add',
        companyPartnerType: this.currentSelectRole.companyPartnerType,
        skuParamList: [
          {
            addCartTime: this.goodItem.addCartTime,
            skuShoppingCartStatus: this.goodItem.skuShoppingCartStatus,
            cacheUniqueId: this.goodItem.cacheUniqueId,
            choiceStatus: this.goodItem.cacheUniqueId
              ? !!this.goodItem.choiceStatus
              : true,
            coverImage: this.coverImage,
            skuCount: this.count,
            skuCode: this.goodItem.skuCode,
            skuFlag: this.goodItem.skuFlag,
            skuName: this.goodItem.skuName,
            skuWalletDTO: {
              customWallet: this.radio.customWallet,
              walletName: this.radio.walletName,
              pickupName: this.radio.pickUpCode ? this.radio.walletName : '',
              pickupNo: this.radio.pickUpPromotionNo,
              price: this.radio.price,
              promotionName: this.radio.promotionName,
              promotionNo: this.radio.promotionNo,
              ticketCode: this.radio.pickUpCode,
            },
          },
        ],
      }
      await shoppingCartApiV2(params).then((res) => {
        if (res.respCode === '10000') {
          this.$store.commit(
            'SET_DISTINCT_COUNT',
            res.respData.mapIneffectiveSkuMap &&
              res.respData.mapIneffectiveSkuMap.effective.length
          )
          Toast(this.goodItem.cacheUniqueId ? '修改成功' : `商品已加入购物车`)
          this.cartVisible = false
          this.$emit(
            'back',
            this.goodItem.cacheUniqueId ? res.respData : this.goodItem
          )
        } else {
          this.$toast.fail(res.respDesc)
        }
      })
    },
    showComb() {
      this.combVisible = true
      this.combSkus = this.goodItem.combSkus
    },
  },
}
</script>

<style scoped lang="less">
::v-deep .van-popup {
  background: #f6f6f6;
}
.account-type-pop {
  width: 100%;
  padding: 12px 0 100px 0;
  box-sizing: border-box;

  .cart-container {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    position: relative;
    .icon-close {
      position: absolute;
      top: 0;
      right: 10px;
      width: 12px;
      height: 12px;
    }
    .cart-title {
      color: @text-color-title;
      font-size: 16px;
      font-weight: 500;
      text-align: center;
      margin: 0 15px 30px 15px;
    }

    .cart-content {
      flex: 1;
      overflow-y: scroll;
      padding: 0 15px;

      .goods-item {
        display: flex;
        margin-top: 3px;
        & + .goods-item {
          margin-top: 20px;
        }

        .image-container {
          position: relative;

          .goods-img {
            width: 90px;
            height: 90px;
          }

          .comb-icon {
            position: absolute;
            width: 32px;
            height: 19px;
            top: -3px;
            left: -3px;
          }
        }

        .goods-info {
          margin-left: 10px;
          //display: flex;
          //flex-direction: column;
          //justify-content: space-between;

          .title-info-container {
            -webkit-line-clamp: 2;
            display: -webkit-box;
            text-overflow: ellipsis;
            overflow: hidden;
            -webkit-box-orient: vertical;
            padding-right: 20px;
            .goods-tag {
              background-color: @primary-color;
              color: @white;
              border-radius: 2px;
              padding: 2.5px 5px;
              font-size: 10px;
              margin-right: 5px;
              margin-top: -5px;
              display: inline-block;
            }

            .goods-title {
              padding-top: 2px;

              font-size: 14px;
              line-height: 21px;
              color: @text-color-title;
            }
          }

          .price-container {
            margin: 14px 0 6px;
            display: flex;
            align-items: center;
            .vip {
              width: 23px;
              height: 11px;
              margin-left: 3px;
            }
          }

          .unit-container {
            .unit {
              color: @text-color-tip;
              height: 21px;
              font-size: 15px;
              font-weight: 400;
              line-height: 21px;
            }

            .van-icon {
              background-color: #f7ecec;
              border-radius: 50%;
              width: 30px;
              height: 30px;
              line-height: 30px;
              font-size: 18px;
              color: @primary-color;
              text-align: center;
            }

            .buy-btn {
              height: 30px;
              color: @primary-color;
              border: 1px solid @border-color-low;
            }
          }
        }
      }

      .purchase-method {
        margin-top: 30px;

        .purchase-title {
          height: 21px;
          font-size: 15px;
          font-weight: 400;
          color: #333333;
          line-height: 21px;
          margin-bottom: 8px;
        }

        .purchase-method-item {
          display: inline-block;
          font-size: 13px;
          border-radius: 4px;
          border: 1px solid #dddddd;
          font-weight: 400;
          cursor: default;
          padding: 6px 18px 5px 18px;
          margin-right: 15px;
          box-sizing: border-box;
        }

        .purchase-method-item-active {
          border: 1px solid @border-color-low;
          color: @primary-color;
        }
      }

      .buy-container {
        margin-top: 30px;
        height: 21px;
        font-size: 15px;
        font-weight: 400;
        color: #333333;
        line-height: 21px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .buy-limit {
          color: @text-color-tip;
          font-size: 12px;
          font-weight: 400;
        }
      }
    }

    .wallet-item {
      padding: 12px 15px;
      img {
        width: 20px;
        height: 20px;
      }
    }
  }
  .tips-bottom {
    width: 100%;
    position: fixed;
    bottom: 0;
    .count-down {
      width: 244px;
      height: 25px;
      background: url('@/assets/qyOrder/count-down.png');
      background-size: 100% 100%;
      position: relative;
      .time {
        position: absolute;
        right: 12px;
        top: 3px;
        font-size: 12px;
        color: #fa3725;
      }
    }
  }
  .tips {
    color: #ff4e20;
    background: #ffeae3;
    font-size: 12px;
  }
  .wallet-tip {
    position: relative;
    .wallet-tip-content {
      position: absolute;
      right: 15px;
      top: 20px;
      border-radius: 6px;
      background: rgba(0, 0, 0, 0.7);
      color: @white;
      padding: 12px;
      width: 252px;
      text-align: left;
    }
    .wallet-tip-content::after {
      position: absolute;
      top: -3px;
      right: 14px;
      border: 0.08rem solid;
      border-color: transparent transparent #000 #000;
      transform: rotate(135deg);
      opacity: 0.7;
      content: '';
    }
  }
}
</style>
