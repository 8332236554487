/**
 * 账户类型
 */
export const AccountTypes = [
  // {
  //   value: 'ACC_CASH_BAL',
  //   label: '现金余额账户',
  // },
  // {
  //   value: 'ACC_NOCASH_BAL',
  //   label: '非现金余额账户',
  // },
  // {
  //   value: 'ACC_NOCASH_MBR',
  //   label: '非现金会员价',
  // },
  // {
  //   value: 'ACC_NOCASH_RTL',
  //   label: '非现金零售价',
  // },
  // {
  //   value: 'ACC_CASH_SPL',
  //   label: '现金小样',
  // },
  // {
  //   value: 'ACC_NOCASH_MAT',
  //   label: '非现金物料',
  // },
]

export const AccountTypeConst = {
  hasAccount: true,
  hasNoAccount: false,
}

export const AmountLevelConst = {
  lv1: '10',
  lv2: '5.98',
}

/**
 * 邀请步骤
 */
export const InviteSteps = {
  [AccountTypeConst.hasAccount]: ['邀请店主', '选择城主地区', '选择账号'],
  [AccountTypeConst.hasNoAccount]: ['邀请店主', '邀请完成'],
}
