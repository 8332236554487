import { getStore, removeStore, setStore } from '@/utils/store'
import { getCustomerTagByCode } from '@/views/shoppingCart/api'
import { getDict } from '@/service/common'

const cart = {
  state: {
    shoppingCart: getStore({ name: 'shopping-cart-info' }) || {},
    customerTags: [],
    accountTypeList: [],
    promotionSku: {},
    //购物车商品数量
    distinctGoodsCount: getStore({ name: 'distinct-goods-count' }) || 0,
    //箱规单位
    unitList: [],
    //账户类型
    AccountType: null,
    // 主体
    subjectInfo: null,
  },
  getters: {
    shoppingCart: (state) => state.shoppingCart,
    customerTags: (state) => state.customerTags,
    getAccountType: (state) => state.accountTypeList,
    getPromotionSku: (state) => state.promotionSku,
    getDistinctGoodsCount: (state) => state.distinctGoodsCount,
    getUnitsList: (state) => state.unitList || getStore({ name: 'unit-list' }),
    accountType: (state) => state.AccountType,
    subject: (state) => state.subjectInfo || getStore({ name: 'subject-info' }),
  },
  mutations: {
    //保存购物车
    SET_SHOPPING_CART: (state, shoppingCart) => {
      state.shoppingCart = shoppingCart
      setStore({
        name: 'shopping-cart-info',
        content: state.shoppingCart,
        type: 'session',
      })
    },
    //清除购物车
    REMOVE_SHOPPING_CART: (state) => {
      state.shoppingCart = {}
      removeStore({
        name: 'shopping-cart-info',
        type: 'session',
      })
    },
    SET_CUSTOMER_TAG: (state, tagList) => {
      state.customerTags = tagList
    },
    //账户类型
    SET_ACCOUNT_TYPE: (state, tagList) => {
      state.accountTypeList = tagList
    },
    //保存选中的活动商品
    SET_PROMOTIONSKU: (state, info) => {
      state.promotionSku = info
    },
    //保存选中的活动商品
    REMOVE_PROMOTIONSKU: (state) => {
      state.promotionSku = {}
    },
    //保存购物车商品数量
    SET_DISTINCT_COUNT: (state, count) => {
      state.distinctGoodsCount = count
      setStore({
        name: 'distinct-goods-count',
        content: count,
        type: 'session',
      })
    },
    //清除购物车商品数量
    REMOVE_DISTINCT_COUNT: (state, count) => {
      state.distinctGoodsCount = count
      removeStore({
        name: 'distinct-goods-count',
        type: 'session',
      })
    },
    //保存商品单位
    SET_UNIT_LIST: (state, count) => {
      state.unitList = count
      setStore({
        name: 'unit-list',
        content: count,
        type: 'session',
      })
    },
    //保存账户类型
    SET_ACCOUNT: (state, count) => {
      state.AccountType = count
    },
    SET_SUBJECT: (state, subject) => {
      state.subjectInfo = subject
      setStore({
        name: 'subject-info',
        content: subject,
        type: 'session',
      })
    },
  },
  actions: {
    // 获取客户标签
    CustomerTagList({ commit }, code) {
      return new Promise((resolve, reject) => {
        getCustomerTagByCode(code)
          .then((res) => {
            commit('SET_CUSTOMER_TAG', res.respData)
            resolve(res.respData)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    // 获取账户类型
    CustomerType({ commit }) {
      return new Promise((resolve, reject) => {
        getDict('cust_account')
          .then((res) => {
            commit('SET_ACCOUNT_TYPE', res.data)
            resolve(res.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    // 获取箱规单位
    UnitType({ commit }) {
      return new Promise((resolve, reject) => {
        getDict('dg_box_spec')
          .then((res) => {
            commit('SET_UNIT_LIST', res.data)
            resolve(res.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
  },
}
export default cart
