<template>
  <div class="price-text-container d-flex a-item-baseline">
    <span class="font-13 font-weight-500">￥</span>
    <span class="font-18 font-DIN-Alternate-Bold font-weight">
      {{ price }}
    </span>
  </div>
</template>
<script>
/**
 * price-text 价格文字组件
 */
export default {
  name: 'price-text',
  props: {
    price: {
      type: [Number, String],
      default: '',
    },
  },
  data() {
    return {}
  },
  methods: {},
}
</script>

<style scoped lang="less">
.price-text-container {
  color: @price-color;
}
</style>
