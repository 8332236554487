export default {
  '000': '操作太频繁，请勿重复请求',
  401: '当前操作没有权限',
  403: '当前操作没有权限',
  404: '资源不存在',
  417: '未绑定登录账号，请使用密码登录后绑定',
  423: '演示环境不能操作，如需了解联系我们',
  426: '用户名不存在或密码错误',
  428: '验证码错误,请重新输入',
  429: '请求过频繁',
  479: '演示环境，没有权限操作',
  default: '系统未知错误,请反馈给管理员',
}
