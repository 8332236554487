<template>
  <div id="app">
    <keep-alive :include="include">
      <router-view v-if="$route.meta.keepAlive"></router-view>
      <van-number-keyboard safe-area-inset-bottom />
    </keep-alive>
    <router-view
      v-if="!$route.meta.keepAlive"
      :key="$router.currentRoute.fullPath"
    ></router-view>
    <van-number-keyboard safe-area-inset-bottom />
  </div>
</template>
<script>
import { initScript } from '@/utils/update'
import { mapGetters } from 'vuex'
export default {
  data: () => ({
    include: [],
  }),
  computed: {
    ...mapGetters(['currentSelectMode']),
  },
  watch: {
    $route(to, from) {
      //如果 要 to(进入) 的页面是需要 keepAlive 缓存的，把 name push 进 include数组
      if (to.meta.keepAlive) {
        !this.include.includes(to.name) && this.include.push(to.name)
      }
      //如果 要 form(离开) 的页面是 keepAlive缓存的，
      //再根据 depth 来判断是前进还是后退
      //如果是后退
      const toDepth = to.meta.depth || 0
      if (from.meta.keepAlive && toDepth < from.meta.depth) {
        const index = this.include.indexOf(from.name)
        index !== -1 && this.include.splice(index, 1)
      }
    },
    // 切换模式时清空页面缓存
    currentSelectMode() {
      this.include = []
    },
  },
  async created() {
    await initScript()
  },
}
</script>
