<template>
  <div
    class="cust-content"
    v-if="shopInfo && shopInfo.custLevel === 'shopkeeper'"
  >
    <div class="cust-name-content">
      <span @click="openShop">
        {{ selectItem.shopName }}
        <van-icon name="arrow-down" />
      </span>
    </div>
    <van-popup
      v-model="detailVisible"
      round
      position="bottom"
      closeable
      class="account-type-pop"
      :style="{ height: '70%' }"
      @close="detailVisible = false"
    >
      <div class="popup-contianer">
        <div class="popup-title">切换账号</div>
        <div class="popup-content">
          <van-radio-group v-model="yhno">
            <template v-for="(item, index) in shopInfo.custShopVOList">
              <van-cell
                :border="false"
                :key="item.yhno + index"
                clickable
                @click="handleSelect(item)"
              >
                <template #title>
                  <div class="text-box">
                    <span>{{ item.shopName }}</span>
                  </div>
                </template>
                <template #right-icon>
                  <van-radio :name="item.yhno" checked-color="#B94B4B" />
                </template>
              </van-cell>
            </template>
          </van-radio-group>
        </div>
      </div>
      <div class="bottom-button-container">
        <van-button
          class="btn-normal"
          block
          round
          color="#B94B4B"
          size="large"
          @click="submitChoose"
        >
          确定
        </van-button>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { getCityYH } from './api/home.api'
import { getStore, setStore, removeStore } from '@/utils/store'

export default {
  name: 'custBar',
  data() {
    return {
      customerInfo: JSON.parse(sessionStorage.getItem('customerInfo')).content,
      shopInfo: {},
      detailVisible: false,
      selectItem: {},
      custLevel: '',
    }
  },
  computed: {
    yhno: {
      get() {
        return this.selectItem.yhno
      },
      set() {},
    },
  },

  mounted() {
    this.shopInfo = getStore({ name: 'custShopInfo' })
    if (this.shopInfo) {
      this.storeShopInfo(this.shopInfo)
    } else {
      this.getCityYHInfo()
    }
  },
  methods: {
    getCityYHInfo() {
      removeStore({ name: 'custShopInfo' })
      removeStore({ name: 'currentShop' })
      removeStore({ name: 'yhno' })
      removeStore({ name: 'yhnoName' })
      const custCode = this.customerInfo.custCode
      getCityYH(custCode).then((res) => {
        this.shopInfo = res.respData
        setStore({
          name: 'custShopInfo',
          content: res.respData,
          type: 'session',
        })
        this.storeShopInfo(res.respData)
      })
    },
    storeShopInfo(respData) {
      if (respData.custLevel === 'shopkeeper') {
        this.selectItem = getStore({ name: 'currentShop' })
        if (!this.selectItem) {
          this.selectItem = respData.custShopVOList[0]
          setStore({
            name: 'currentShop',
            content: this.selectItem,
            type: 'session',
          })
          setStore({
            name: 'yhno',
            content: this.selectItem.yhno,
            type: 'session',
          })
          setStore({
            name: 'yhnoName',
            content: this.selectItem.shopName,
            type: 'session',
          })
        }
      } else {
        setStore({
          name: 'currentShop',
          content: this.shopInfo,
          type: 'session',
        })
        setStore({
          name: 'yhno',
          content: this.shopInfo.yhno,
          type: 'session',
        })
        setStore({
          name: 'yhnoName',
          content: '一人一城',
          type: 'session',
        })
      }
      this.$emit('load')
    },
    openShop() {
      if (this.shopInfo.custLevel === 'lord') {
        return
      }
      this.detailVisible = true
    },
    handleSelect(val) {
      this.selectItem = val
    },
    submitChoose() {
      removeStore({ name: 'currentShop' })
      removeStore({ name: 'yhno' })
      removeStore({ name: 'yhnoName' })
      setStore({
        name: 'currentShop',
        content: this.selectItem,
        type: 'session',
      })
      setStore({
        name: 'yhno',
        content: this.selectItem.yhno,
        type: 'session',
      })
      setStore({
        name: 'yhnoName',
        content: this.selectItem.shopName,
        type: 'session',
      })
      this.detailVisible = false
    },
  },
}
</script>

<style scoped lang="less">
.cust-name-content {
  height: 20px;
  line-height: 20px;
  margin-bottom: 12px;
  font-size: 14px;
  color: @text-color-title;
  .van-icon {
    margin-left: 6px;
    font-size: 15px;
  }
}

.account-type-pop {
  width: 100%;
  padding-bottom: 90px;
  box-sizing: border-box;

  .popup-contianer {
    padding: 15px 15px 0 15px;
    height: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;

    .popup-title {
      color: @text-color-title;
      font-size: 16px;
      font-weight: bold;
      text-align: center;
      margin-bottom: 14px;
    }

    .popup-content {
      height: 100%;
      overflow-y: scroll;

      .text-box {
        // height: 50px;
        font-size: 16px;
        color: @text-color-main;
        display: flex;
        align-items: center;

        .title-tag {
          color: @white;
          background-color: @primary-color;
          padding: 1px 5px;
          font-size: 10px;
          border-radius: 2px;
          margin-right: 10px;
        }
      }
    }
  }
}
</style>
