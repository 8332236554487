import request, { get, post } from '@/service/request'
import qs from 'qs'
// 登录接口
export const login = (params) => get(`/auth/oauth/token`, params)
// 获取租户信息接口
export const getTenantId = (username) => get(`/admin/user/tenantId/${username}`)

// 用户名密码登录
export const loginByUsername = (username, password, code, randomStr) => {
  // 此处在取用户名时后端会多一个引号导致取不到 暂时先添加一个参数使登录正常
  const dataObj = qs.stringify({ userName: username, username, password })
  return request({
    url: '/auth/oauth/token',
    headers: {
      isToken: false,
      'TENANT-ID': '1',
      Authorization: 'Basic cGlnOnBpZw==',
      'PROGRAM-CODE': 'DMS',
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    method: 'post',
    params: { randomStr, code, grant_type: 'password' },
    data: dataObj,
  })
}
// 重置密码
export const resetPassword = (params) =>
  post('/admin/user/resetPassWord', params)

// 发送验证码
export const getSmsCode = (mobile) => get(`/admin/mobile/${mobile}`)

/**
 * 实名认证 三因子
 */
export const authorization = (data) =>
  post('/customer/h5/cust/customer/verified', data, {
    headers: { ignoreSaleChannel: true },
  })

export const getTokenByCode = (code, appId) =>
  get(`/admin/user/wx/authorization_code/${code}`, {}, { appId: appId })
export const getBindInfoByUnionId = (unionId) =>
  get(`/admin/user/wx/bind/info/${unionId}`, {})
export const saveBindInfo = (data) => post(`/admin/user/wx/bind`, data)
