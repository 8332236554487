import { getWxConfig } from '@/views/qyOrder/model'
import { Toast } from 'vant'

//https://developers.weixin.qq.com/community/develop/doc/000e8a9c5f87f8f52ff9593355d800

export const createWxEle = () => {
  window.wx = null
  window.jWeixin = null
  if (document.getElementById('wxScriptId')) {
    removeJweixin()
  }
  appendjweixin()
}
export const getWxConfigFun = (shareMes) => {
  let url = ''
  const ua = navigator.userAgent.toLowerCase()
  if (/iphone|ipad|ipod/.test(ua)) {
    url = window.location.href.split('#')[0]
  } else if (/android/.test(ua)) {
    url = window.location.href
  }
  let appId = 'wx5111ee55db77a6f3'
  if (url.includes('test')) {
    // 测试环境appId
    appId = 'wx97d09602e3a3050f'
  }
  getWxConfig({ url })
    .then((res) => {
      // this.artDetail = res.data.data
      const config = res.data
      window.wx.checkJsApi({
        jsApiList: [
          'updateAppMessageShareData',
          'updateTimelineShareData',
          'onMenuShareTimeline',
          'onMenuShareAppMessage',
        ], // 需要检测的JS接口列表，所有JS接口列表见附录2,
        success: function (res) {
          console.log(res)
        },
      })
      window.wx.config({
        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        appId: appId, // 必填，公众号的唯一标识
        timestamp: config.timestamp, // 必填，生成签名的时间戳
        nonceStr: config.nonceStr, // 必填，生成签名的随机串
        signature: config.signature, // 必填，签名
        jsApiList: [
          'updateAppMessageShareData',
          'updateTimelineShareData',
          'onMenuShareTimeline',
          'onMenuShareAppMessage',
        ], // 必填，需要使用的JS接口列表
      })
      window.wx.error(function (res) {
        // alert('出错了：' + res.errMsg) //这个地方的好处就是window.wx.config配置错误，会弹出窗口哪里错误，然后根据微信文档查询即可。
        console.log(res.errMsg)
      })
      window.wx.ready(() => {
        console.log(1111111111111)
        wx_share(shareMes)
        wx_TimelineShare(shareMes)
      })
    })
    .catch((error) => {
      Toast.fail(error)
    })
}

function appendjweixin() {
  const ele = document.createElement('script')
  ele.type = 'text/javascript'
  ele.src = 'https://res.wx.qq.com/open/js/jweixin-1.6.0.js'
  ele.id = 'wxScriptId'
  document.body.appendChild(ele)
}

function removeJweixin() {
  const ele = document.getElementById('wxScriptId')
  document.body.removeChild(ele)
}

function wx_share({ title, link, imgUrl, desc }) {
  //微信好友
  window.wx.updateAppMessageShareData({
    title: title, // 分享标题
    desc: desc, // 分享描述
    link: link, // 分享链接
    imgUrl: imgUrl, // 分享图标
    success: function () {
      // 用户确认分享后执行的回调函数
    },
    cancel: function () {
      // 用户取消分享后执行的回调函数
    },
  })
}

function wx_TimelineShare({ title, link, imgUrl, desc }) {
  //朋友圈
  window.wx.updateTimelineShareData({
    title: title, // 分享标题
    link: link, // 分享链接
    imgUrl: imgUrl, // 分享图标
    desc: desc, // 分享描述
    success: function () {
      // 用户确认分享后执行的回调函数
    },
    cancel: function () {
      // 用户取消分享后执行的回调函数
    },
  })
}
