<template>
  <div>
    <van-field name="radio" label="云店模式" :border="false">
      <template #input>
        <van-radio-group
          :value="info.cloudAccount"
          direction="horizontal"
          checked-color="#b94b4b"
          icon-size="16px"
        >
          <van-radio
            :name="accountTypeConst.hasAccount"
            @click="onChangeVal('cloudAccount', accountTypeConst.hasAccount)"
          >
            有账号
          </van-radio>
          <van-radio
            :name="accountTypeConst.hasNoAccount"
            @click="onChangeVal('cloudAccount', accountTypeConst.hasNoAccount)"
          >
            无账号
          </van-radio>
        </van-radio-group>
      </template>
    </van-field>
    <van-field name="radio" label="回款门槛">
      <template #input>
        <van-radio-group
          :value="info.paymentAmount"
          direction="horizontal"
          checked-color="#b94b4b"
          icon-size="16px"
        >
          <van-radio
            :name="amountLevelConst.lv1"
            @click="onChangeVal('paymentAmount', amountLevelConst.lv1)"
          >
            10w档
          </van-radio>
          <van-radio
            :name="amountLevelConst.lv2"
            @click="onChangeVal('paymentAmount', amountLevelConst.lv2)"
          >
            5.98w档
          </van-radio>
        </van-radio-group>
      </template>
    </van-field>
  </div>
</template>

<script>
import { AccountTypeConst, AmountLevelConst } from '@/views/tabbar/const/const'
import _ from 'lodash'

export default {
  model: {
    prop: 'info',
    event: 'change',
  },
  props: {
    info: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      amountLevelConst: AmountLevelConst,
      accountTypeConst: AccountTypeConst,
    }
  },
  methods: {
    onChangeVal(prop, val) {
      const info = _.cloneDeep(this.info)
      info[prop] = val
      this.$emit('change', info)
    },
  },
}
</script>

<style scoped></style>
