<template>
  <div class="region-container">
    <van-radio-group
      :value="info.selectedId"
      direction="horizontal"
      checked-color="#b94b4b"
      icon-size="16px"
    >
      <van-field
        name="radio"
        :label="
          item.shopSource === 'transform'
            ? '全国'
            : item.cityRegion || item.cityCity || item.cityProvince
        "
        v-for="item in lordRegion"
        :key="item.id"
        input-align="right"
        :border="false"
        clickable
        @click="onChangeVal(item)"
      >
        <template #input>
          <van-radio :name="item.id" @click="onChangeVal(item)" />
        </template>
      </van-field>
    </van-radio-group>
  </div>
</template>

<script>
import { getLordRegionByCode } from '@/views/tabbar/api/goods'
import { mapGetters } from 'vuex'
import { BizState } from '@/const/common'
import { Toast } from 'vant'
import _ from 'lodash'

export default {
  model: {
    prop: 'info',
    event: 'change',
  },
  props: {
    info: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      lordRegion: [],
    }
  },
  computed: {
    ...mapGetters(['customerInfo']),
    checked() {
      return (
        this.info.regionCode ||
        this.info.cityCode ||
        this.info.provinceCode ||
        null
      )
    },
  },
  mounted() {
    this.getLordRegion()
  },
  methods: {
    getLordRegion() {
      getLordRegionByCode(this.customerInfo.custCode).then((res) => {
        if (res.respCode === BizState.success) {
          res.respData.forEach((item) => {
            item.key = item.regionCode || item.cityCode || item.provinceCode
          })
          this.lordRegion = res.respData
        } else {
          Toast.fail(res.respDesc || res.msg)
        }
      })
    },
    onChangeVal(item) {
      const info = _.cloneDeep(this.info)
      info.selectedId = item.id
      info.province = item.cityProvince
      info.provinceCode = item.cityProvinceCode
      info.city = item.cityCity
      info.cityCode = item.cityCityCode
      info.region = item.cityRegion
      info.regionCode = item.cityRegionCode
      this.$emit('change', info)
    },
  },
}
</script>

<style scoped></style>
