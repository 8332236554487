<template>
  <div class="third-step-container">
    <van-search
      v-model="searKey"
      shape="round"
      show-action
      label="搜索"
      placeholder="请输入客户名称/手机号"
      @search="onSearch"
    >
      <template #action>
        <div @click="onSearch">搜索</div>
      </template>
    </van-search>
    <div class="customer-info-container">
      <template v-if="list.length">
        <van-radio-group
          :value="info.cloudCustSaleChannelId"
          checked-color="#b94b4b"
          icon-size="16px"
        >
          <div
            class="customer-info-card"
            v-for="item in list"
            :key="item.id"
            @click="onClick(item)"
          >
            <div class="left-info">
              <div class="name-container">
                <span class="name">{{ item.custName }}</span>
                <van-tag>{{ customerLevelLabelConst[item.custLevel] }}</van-tag>
              </div>
              <div class="phone">{{ item.custPhone }}</div>
            </div>
            <van-radio :name="item.id" @click.stop="onClick(item)"></van-radio>
          </div>
        </van-radio-group>
      </template>
      <common-empty v-if="list.length === 0"></common-empty>
    </div>
  </div>
</template>

<script>
import CommonEmpty from '@/components/commonEmpty'
import { getCloudCustomerList } from '@/views/tabbar/api/goods'
import { BizState, CustomerLevelLabelConst } from '@/const/common'
import { Toast } from 'vant'
import _ from 'lodash'

export default {
  components: { CommonEmpty },
  model: {
    prop: 'info',
    event: 'change',
  },
  props: {
    info: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      searKey: null,
      list: [],
      customerLevelLabelConst: CustomerLevelLabelConst,
    }
  },
  mounted() {
    if (this.info.selectedCustomer) {
      this.list = [this.info.selectedCustomer]
    }
  },
  methods: {
    onSearch() {
      if (_.isEmpty(this.searKey)) {
        Toast.fail('请输入搜索内容')
        return
      }
      const param = {
        custName: this.searKey,
        noCity: true,
      }

      const info = _.cloneDeep(this.info)
      info.cloudCustSaleChannelId = null
      info.selectedCustomer = null
      this.$emit('change', info)

      getCloudCustomerList(param).then((res) => {
        if (res.respCode === BizState.success) {
          this.list = res.respData
        } else {
          Toast.fail(res.respDesc || res.msg)
        }
      })
    },
    onClick(item) {
      const info = _.cloneDeep(this.info)
      info.cloudCustSaleChannelId = item.id
      info.selectedCustomer = item
      this.$emit('change', info)
    },
  },
}
</script>

<style scoped lang="less">
.third-step-container {
  .customer-info-container {
    padding: 20px 0;
    max-height: 40vh;
    overflow-y: auto;

    .customer-info-card {
      margin: 0 20px;
      border-radius: @border-radius;
      box-shadow: @box-shadow;
      display: flex;
      align-items: center;
      padding: 12px;

      .left-info {
        flex-grow: 1;

        .name-container {
          display: flex;
          align-items: center;
          margin-bottom: 12px;

          .name {
            font-size: 16px;
            font-weight: 500;
            color: @text-color-title;
            margin-right: 10px;
          }

          .van-tag {
            font-size: 10px;
          }
        }

        .phone {
          color: @text-color-title;
          font-size: 14px;
          font-weight: 500;
        }
      }

      & + .customer-info-card {
        margin-top: 12px;
      }
    }
  }
}
</style>
