<!--购物车角标-->
<template>
  <div class="shopping-cart-container">
    <van-icon
      :name="require('@/assets/qyOrder/shoppingCart.png')"
      color="#B94B4B"
      size="23"
      :badge="cartCount"
      @click="goShoppingCart"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { getShoppingCart } from '@/views/shoppingCart/api'
import _ from 'lodash'
import { getStore } from '@/utils/store'
import { shoppingCartApiV2 } from '@/views/shoppingCart/apis'

export default {
  name: 'ShoppingCartIcon',
  props: {
    saleAccType: {
      type: String,
      default: 'ACC_CASH_BAL',
    },
  },
  data() {
    return {
      cartCount: 0,
      customerInfo: JSON.parse(sessionStorage.getItem('customerInfo')).content,
      yhno: '',
      accountTypes: [],
    }
  },
  computed: {
    ...mapGetters([
      'getDistinctGoodsCount',
      'shoppingCart',
      'getAccountType',
      'saleMode',
      'currentSelectRole',
    ]),
  },
  watch: {
    saleAccType(newVal, oldVal) {
      if (newVal) {
        this.$nextTick(() => {
          this.getCartInfo()
        })
      }
    },
  },
  async mounted() {
    // this.getCartInfo()
    await this.getDict()
    this.getStorage()
  },
  activated() {
    this.getStorage()
  },
  methods: {
    //获取字典
    async getDict() {
      if (!_.isEmpty(this.getAccountType)) {
        this.accountTypes = this.getAccountType
      } else {
        await this.$store.dispatch('CustomerType').then((res) => {
          this.accountTypes = res
        })
      }
    },
    goShoppingCart() {
      this.$router.push({
        name: this.saleMode ? 'newShoppingCart' : 'shoppingCart',
        params: {
          accountType: this.saleAccType,
        },
      })
    },
    getStorage() {
      //   console.log(this.shoppingCart,this.getDistinctGoodsCount)
      this.yhno = getStore({ name: 'yhno' })
      const shoppingCartCount = this.getDistinctGoodsCount
      if (!_.isEmpty(this.shoppingCart)) {
        this.cartCount = shoppingCartCount
      } else {
        this.getCartInfo()
      }
    },
    async getCartInfo() {
      const params = this.saleMode
        ? {
            custCode: this.currentSelectRole.custCode,
            custSubjectCode: this.currentSelectRole.creditCard,
            operate: 'query',
            companyPartnerType: this.currentSelectRole.companyPartnerType,
          }
        : {
            operate: 'query',
            saleAccType: this.saleAccType,
            custCode: this.customerInfo.custCode,
            yhno: this.yhno ? this.yhno : '',
            saleMode: this.saleMode ? 'bcompany' : '',
            companyPartnerType: this.saleMode
              ? this.currentSelectRole.companyPartnerType
              : '',
            creditCard: this.saleMode ? this.currentSelectRole.creditCard : '',
          }
      const { respCode, respData } = this.saleMode
        ? await shoppingCartApiV2(params)
        : await getShoppingCart(params)
      if (respCode === '10000') {
        this.cartCount = respData.distinctGoodsCount
        this.$store.commit('REMOVE_SHOPPING_CART')
        this.$store.commit('REMOVE_DISTINCT_COUNT')
        this.$store.commit('SET_SHOPPING_CART', Object.assign({}, respData))
        this.$store.commit(
          'SET_DISTINCT_COUNT',
          this.saleMode
            ? respData.mapIneffectiveSkuMap &&
                respData.mapIneffectiveSkuMap.effective.length
            : respData.distinctGoodsCount
        )
      }
    },
  },
}
</script>

<style scoped lang="less">
.shopping-cart-container {
  position: fixed;
  bottom: 70px;
  left: 16px;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  box-shadow: 1px 2px 5px 1px rgba(0, 0, 0, 0.02),
    0 1.5px 4px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.04);

  .van-icon__image {
    width: 46px;
    height: 46px;
  }

  .van-info {
    position: absolute;
    top: 10px;
    right: 10px;
    background: @primary-color;
  }
}
</style>
