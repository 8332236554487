import { get } from './request'
import request from './request'

export const codeLogin = (mobile) => get(`/admin/mobile/${mobile}`)

export const loginByMobile = (mobile, code, corpId) => {
  const grant_type = 'mobile'
  return request({
    url: '/auth/mobile/token/sms',
    headers: {
      isToken: false,
      'TENANT-ID': '1',
      Authorization: 'Basic cGlnOnBpZw==',
    },
    method: 'post',
    params: { mobile: 'SMS@' + mobile, code: code, grant_type, corpId },
  })
}

export const refreshToken = (refresh_token) => {
  const grant_type = 'refresh_token'
  return request({
    url: '/auth/oauth/token',
    headers: {
      isToken: false,
      'TENANT-ID': '1',
      Authorization: 'Basic cGlnOnBpZw==',
    },
    method: 'post',
    params: { refresh_token, grant_type },
  })
}
