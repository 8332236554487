<template>
  <div class="search_container" @click="onFocusSearch">
    <div class="search-bg-white" :style="{ background: color }">
      <img src="../../../assets/home/search.png" alt="" />
      请输入搜索内容
    </div>
    <div class="search-button">搜索</div>
  </div>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: '#fff',
    },
    path: {
      type: String,
      default: '/search',
    },
  },
  data() {
    return {}
  },
  methods: {
    onFocusSearch() {
      this.$router.push({ path: this.path })
    },
  },
}
</script>

<style lang="less" scoped>
.search_container {
  padding-top: 0;
  padding-left: 0;
  padding-bottom: 12px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  position: relative;
  .search-bg-white {
    flex: 1;
    height: 26px;
    display: flex;
    align-items: center;
    background: #fff;
    border-radius: 50px;
    padding: 5px 0;
    padding-left: 12px;
    color: #acacac;
    font-size: 14px;
    img {
      width: 16px;
      height: 16px;
      margin-right: 6px;
    }
  }
  .search-button {
    position: absolute;
    top: 5px;
    right: 5px;
    width: 50px;
    height: 26px;
    background: linear-gradient(141deg, #ff8674 0%, #ff4361 100%);
    border-radius: 13px;
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;
    line-height: 26px;
    text-align: center;
  }
}
</style>
