import Vue from 'vue'
import Vuex from 'vuex'
import getters from './getters'
import user from './modules/user'
import cart from '@/store/modules/cart'
import shoppingPackage from '@/store/modules/shoppingPackage'
import behalfCart from '@/store/modules/behalfCart'
import customer from '@/store/modules/customer'
Vue.use(Vuex)

export default new Vuex.Store({
  getters,
  modules: { user, cart, shoppingPackage, behalfCart, customer },
})
