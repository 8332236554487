import customDialog from './customDialog.vue'
import Vue from 'vue'

Vue.prototype.$newConfirm = function (options) {
  let resolveFunction,
    rejectFunction,
    visible = true
  let vm = null,
    closeMessageBox = null
  const asyncPromise = new Promise((resolve, reject) => {
    resolveFunction = resolve
    rejectFunction = reject
  })

  const instance = new Vue({
    render(h) {
      return h(customDialog, {
        props: {
          visible,
          ...options,
          resolve: resolveFunction,
          reject: rejectFunction,
        },
        on: {
          'update:visible': (value) => {
            visible = value
            closeMessageBox()
          },
        },
      })
    },
  })

  vm = instance.$mount()
  document.body.appendChild(vm.$el)

  closeMessageBox = () => {
    document.body.removeChild(vm.$el)
    vm.$destroy()
  }

  return asyncPromise
}
