<template>
  <div class="invite-shopkeeper-container">
    <van-popup
      transition-appear
      closeable
      :style="{ height: '80vh' }"
      :close-on-click-overlay="false"
      :value="visible"
      get-container=".invite-shopkeeper-container"
      round
      position="bottom"
      @click-close-icon="onClose"
      @click-overlay="onClose"
      @close="onClose"
    >
      <div class="popup-body-container">
        <div class="invite-shopkeeper-content">
          <van-steps
            :active="active"
            active-color="#b94b4b"
            inactive-color="#999999"
          >
            <van-step
              v-for="item in inviteSteps[info.cloudAccount]"
              :key="item"
            >
              {{ item }}
            </van-step>
          </van-steps>
          <div class="step-container">
            <first-step v-model="info" v-if="active === 0"></first-step>
            <second-step
              v-model="info"
              v-if="
                active === 1 &&
                info.cloudAccount === accountTypeConst.hasAccount
              "
            ></second-step>
            <third-step v-model="info" v-if="active === 2"></third-step>
            <last-step
              :qr-img="info.qrImg"
              v-if="
                info.cloudAccount === accountTypeConst.hasNoAccount &&
                active === 1
              "
            ></last-step>
          </div>
        </div>
        <div class="btn-container">
          <van-button
            color="#b94b4b"
            plain
            round
            v-if="active > 0"
            @click="onChangeStep(active - 1)"
          >
            上一步
          </van-button>
          <van-button
            color="#b94b4b"
            round
            v-if="active < inviteSteps[info.cloudAccount].length - 1"
            @click="onChangeStep(active + 1)"
          >
            下一步
          </van-button>

          <van-button
            color="#b94b4b"
            round
            v-if="active === inviteSteps[info.cloudAccount].length - 1"
            @click="onConfirm"
          >
            确定
          </van-button>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import {
  AccountTypeConst,
  AmountLevelConst,
  InviteSteps,
} from '@/views/tabbar/const/const'
import FirstStep from './first-step'
import SecondStep from './second-step'
import ThirdStep from './third-step'
import LastStep from './last-step'
import { createQrCode } from '@/views/tabbar/api/goods'
import { BizState, CustomerLevelConst } from '@/const/common'
import { Toast } from 'vant'
import _ from 'lodash'

export default {
  components: {
    FirstStep,
    SecondStep,
    ThirdStep,
    LastStep,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      inviteSteps: InviteSteps,
      active: 0,
      accountTypeConst: AccountTypeConst,
      info: {
        custLevel: CustomerLevelConst.shopkeeper,
        cloudAccount: AccountTypeConst.hasAccount,
        paymentAmount: AmountLevelConst.lv2,
        selectedId: null,
        province: null,
        provinceCode: null,
        city: null,
        cityCode: null,
        region: null,
        regionCode: null,
        // 云店账号
        cloudCustSaleChannelId: null,
        selectedCustomer: null,
        qrImg: null,
      },
    }
  },
  methods: {
    onClose() {
      this.$emit('update:visible', false)
    },
    async onChangeStep(step) {
      if (step < this.active) {
        this.active = step
        return
      }
      let flag = true
      switch (step) {
        case 1:
          if (this.info.cloudAccount === AccountTypeConst.hasNoAccount) {
            flag = await this.getQrCode()
          }
          break
        case 2:
          flag = this.checkSecondStep()
          break
        case 3:
          flag = this.checkThirdStep() && (await this.getQrCode())
          break
        default:
          break
      }
      if (flag) {
        this.active = step
      }
    },
    checkThirdStep() {
      if (!this.info.selectedCustomer) {
        Toast.fail('请选择账号')
        return false
      }
      return true
    },
    checkSecondStep() {
      if (!this.info.selectedId) {
        Toast.fail('请选择地区')
        return false
      }
      return true
    },
    async getQrCode() {
      const param = _.cloneDeep(this.info)
      delete param.selectedCustomer
      delete param.selectedId
      const res = await createQrCode(param)
      if (res.respCode === BizState.success) {
        if (this.info.cloudAccount === AccountTypeConst.hasNoAccount) {
          this.info.qrImg = res.respData
          return true
        } else {
          Toast.success('操作成功')
          this.onClose()
        }
      } else {
        Toast.fail(res.respDesc || res.msg)
        return false
      }
    },
    onConfirm() {
      this.onClose()
    },
  },
}
</script>

<style scoped lang="less">
.invite-shopkeeper-container {
  .popup-body-container {
    position: relative;
    height: calc(100% - 30px);
    padding: 15px 25px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .invite-shopkeeper-content {
      padding-top: 10px;

      .step-container {
        margin-top: 15px;
      }
    }

    .btn-container {
      text-align: center;

      .van-button {
        width: 150px;

        & + .van-button {
          margin-left: 10px;
        }
      }
    }
  }
}
</style>
