<template>
  <div class="recommend-container">
    <div class="recommend-title">新品推荐</div>
    <van-list
      v-model="loading"
      ref="scroll"
      :finished="finished"
      @load="getList"
    >
      <van-grid :column-num="1" :border="false">
        <van-grid-item v-for="(product, index) in list" :key="index">
          <product-card
            :image="product.coverImage"
            :title="product.skuName"
            :tags="product.tagNames"
            :price="product.price"
            :sku-code="product.skuCode"
            :product="product"
            @purchase="purchase(index)"
          ></product-card>
        </van-grid-item>
      </van-grid>
      <van-divider v-show="finished">{{ finishedText }}</van-divider>
    </van-list>
    <add-to-cart
      v-if="showPurchase"
      :visible="showPurchase"
      :good-item="selectedProduct"
      :cover-image="selectedProduct.coverImage"
      :active-type="'cart'"
      :sale-channel="saleChannel"
      @back="submitChoose"
    ></add-to-cart>
  </div>
</template>

<script>
import ProductCard from './productCard.vue'
import { getNewGoods } from './api/home.api'
import List from '@/mixins/list'
import _ from 'lodash'
import { mapGetters } from 'vuex'
import { getStore } from '@/utils/store'
import { SaleChannelKey } from '@/const/common'
import addToCart from '@/views/newModel/goods/addToCart.vue'

export default {
  components: { ProductCard, addToCart },
  mixins: [List],
  data() {
    return {
      showPurchase: false,
      list: [],
      // pageSize: 10,
      // currentPage: 0,
      selectedProduct: null,
      finishedText: '已经到底了哦～',
      // customerInfo: JSON.parse(sessionStorage.getItem('customerInfo')).content,
      //账户类型列表
      accountTypes: [],
      saleChannel: '',
      yhno: '',
    }
  },
  computed: {
    ...mapGetters([
      'getAccountType',
      'getUnitsList',
      'customerInfo',
      'userInfo',
      'saleMode',
      'currentSelectRole',
    ]),
  },
  mounted() {
    this.yhno = getStore({ name: 'yhno' })
    this.saleChannel = sessionStorage.getItem(SaleChannelKey)
    this.getAccountDict()
    this.getUnitDict()
  },
  methods: {
    //获取字典
    async getAccountDict() {
      if (!_.isEmpty(this.getAccountType)) {
        this.accountTypes = this.getAccountType
      } else {
        await this.$store.dispatch('CustomerType').then((res) => {
          this.accountTypes = res
        })
      }
    },
    //获取字典
    async getUnitDict() {
      if (this.getUnitsList) return
      await this.$store.dispatch('UnitType')
    },
    listApi() {
      return getNewGoods({
        custCode: this.customerInfo.custCode,
        yhno: this.yhno,
        companyPartnerType: this.saleMode
          ? this.currentSelectRole.companyPartnerType
          : '',
      })
    },
    purchase(index) {
      this.showPurchase = true
      this.selectedProduct = this.list[index]
    },
    submitChoose() {
      this.selectedProduct = {}
      this.showPurchase = false
    },
  },
}
</script>

<style lang="less" scoped>
@border-radius: 8px;

.recommend-container {
  margin-top: 16px;
  margin-bottom: 55px;
  .recommend-title {
    height: 22px;
    font-size: 16px;
    font-weight: 500;
    color: #333333;
    line-height: 22px;
    position: relative;
    padding-left: 11px;
    &::after {
      display: block;
      content: '';
      width: 3px;
      height: 12px;
      background: #ff264a;
      border-radius: 2px;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }
  }
}

::v-deep .van-grid-item {
  margin-top: 12px;
  border-radius: @border-radius;
  overflow: hidden;
  .van-grid-item__content {
    padding: 0;
  }
}
//
///deep/ .van-grid-item:nth-child(odd) {
//  padding-right: 4.5px;
//}
//
///deep/ .van-grid-item:nth-child(even) {
//  padding-left: 4.5px;
//}

/deep/ .van-popup__close-icon--top-right {
  top: 12px;
}

.van-divider /deep/ {
  margin-top: 6px;
  margin-left: 50px;
  margin-right: 50px;
}

.van-divider::after /deep/,
.van-divider::before /deep/ {
  border-color: #dddddd;
}
</style>
