export const qyRoute = [
  {
    path: '/qyCustomerList',
    name: 'qyCustomerList',
    component: () => import('@/views/qyOrder/customerList'),
    meta: { title: '会员列表' },
  },
  {
    path: '/qyCustomer/register',
    name: 'qyCustomerRegister',
    component: () => import('@/views/qyOrder/customerRegister'),
    meta: { title: '添加新会员' },
  },
  {
    path: '/cloud/behalfGoods',
    name: 'cloudBehalfGoods',
    component: () => import('@/views/qyOrder/cloud/behalf-goods.vue'),
    meta: { title: '一键代发' },
  },
  {
    path: '/qy/shopping-cart',
    name: 'qyShoppingCart',
    component: () => import('@/views/qyOrder/shoppingCart.vue'),
    meta: { title: '购物车' },
  },
  {
    path: '/qy/search-result',
    name: 'qySearchResult',
    component: () => import('@/views/qyOrder/searchResult.vue'),
    meta: {
      title: '搜索结果',
      depth: 1,
    },
  },
  {
    path: '/qy/add-address',
    name: 'qyAddAddress',
    component: () => import('@/views/qyOrder/components/addAddress.vue'),
    meta: { title: '新增收货地址' },
  },
  {
    path: '/qy/behalf-create',
    name: 'qyBehalfCreate',
    component: () => import('@/views/qyOrder/cloud/behalf-create.vue'),
    meta: { title: '提交订单' },
  },
  {
    path: '/qy/local-deliver',
    name: 'qyLocalDeliver',
    component: () => import('@/views/qyOrder/cloud/localDeliver.vue'),
    meta: { title: '本地发货' },
  },
  {
    path: '/qy/order-success',
    name: 'qyOrderSuccess',
    component: () => import('@/views/qyOrder/components/order-success.vue'),
    meta: { title: '转单成功' },
  },
  {
    path: '/qy/scan',
    name: 'qyScan',
    component: () => import('@/views/qyOrder/cloud/qy-scan.vue'),
    meta: { title: '扫码出货' },
  },
  {
    path: '/qy/orderList',
    name: 'qyOrderList',
    component: () => import('@/views/qyOrder/orderList'),
    meta: { title: '店铺订单' },
  },
  {
    path: '/qy/orderDetail',
    name: 'qyOrderDetail',
    component: () => import('@/views/qyOrder/orderList/detail.vue'),
    meta: { title: '店铺订单详情' },
  },
  {
    path: '/qy/storeReceipt',
    name: 'qyStoreReceipt',
    component: () => import('@/views/qyOrder/storeReceipt'),
    meta: { title: '采购确认' },
  },
  {
    path: '/qy/storeReceipt/detail',
    name: 'qyStoreReceiptDetail',
    component: () => import('@/views/qyOrder/storeReceipt/detail.vue'),
    meta: { title: '采购详情' },
  },
  {
    path: '/qy/storeReceipt/partReceipt',
    name: 'qyStorePartReceipt',
    component: () => import('@/views/qyOrder/storeReceipt/partReceipt.vue'),
    meta: { title: '部分收货' },
  },
  {
    path: '/qy/logistics',
    name: 'qyLogistics',
    component: () => import('@/views/qyOrder/storeReceipt/logisticDetail.vue'),
    meta: { title: '物流信息' },
  },
  {
    path: '/qy/transferDetail',
    name: 'qyTransferDetail',
    component: () => import('@/views/qyOrder/orderList/transferDetail.vue'),
    meta: { title: '转单信息' },
  },
  {
    path: '/qy/invite',
    name: 'qyInvite',
    component: () => import('@/views/qyOrder/invite'),
    meta: { title: '邀请注册' },
  },
  {
    path: '/qy/invite/register',
    name: 'qyInviteRegister',
    component: () => import('@/views/qyOrder/invite/register.vue'),
    meta: { title: '分享注册' },
  },
  {
    path: '/qy/invite/register-success',
    name: 'qyInviteRegisterSuccess',
    component: () => import('@/views/qyOrder/invite/register-success.vue'),
    meta: { title: '注册成功' },
  },
  {
    path: '/qy/storeInventory',
    name: 'qyStoreInventory',
    component: () => import('@/views/qyOrder/storeInventory'),
    meta: { title: '店铺库存' },
  },
  {
    path: '/qy/storeInventory/flow',
    name: 'qyStoreFlow',
    component: () => import('@/views/qyOrder/storeInventory/flow.vue'),
    meta: { title: '出入库明细' },
  },
]
