export default {
  state: {
    confirmFlag: false,
    applyUpgradeConfirm: false,
  },
  mutations: {
    SET_CONFIRM_FLAG: (state, val) => {
      state.confirmFlag = val
    },
    SET_APPLY_UPGRADE_CONFIRM: (state, val) => {
      state.applyUpgradeConfirm = val
    },
  },
}
