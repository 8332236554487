import { render, staticRenderFns } from "./comb-sku.vue?vue&type=template&id=113d128a&scoped=true&"
import script from "./comb-sku.vue?vue&type=script&lang=js&"
export * from "./comb-sku.vue?vue&type=script&lang=js&"
import style0 from "./comb-sku.vue?vue&type=style&index=0&id=113d128a&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "113d128a",
  null
  
)

export default component.exports