import Vue from 'vue'
function getInput(el) {
  let inputEle
  if (el.tagName !== 'INPUT') {
    inputEle = el.querySelector('input')
  } else {
    inputEle = el
  }
  return inputEle
}
function dispatchEvent(el, type) {
  let evt = document.createEvent('HTMLEvents')
  evt.initEvent(type, true, true)
  el.dispatchEvent(evt)
}

Vue.directive('trim', {
  inserted: (el) => {
    let inputEle = getInput(el)
    const handle = (event) => {
      const newVal = event.target.value.replace(/\s+/g, '')
      if (event.target.value != newVal) {
        event.target.value = newVal
        dispatchEvent(inputEle, 'input')
      }
    }
    el.inputEle = inputEle
    el._blueHandler = handle
    inputEle.addEventListener('blur', handle)
  },
  unbind(el) {
    const { inputEle } = el
    inputEle.removeEventListener('blur', el._blueHandler)
  },
})
