import { getStore, setStore } from '@/utils/store'
import { loginByMobile, refreshToken } from '@/service/login'
import { encryption } from '@/utils/utils'
import { loginByUsername } from '@/views/login/api/login.api'
// import { TOKEN } from '@/const/storeConst'

const user = {
  state: {
    userInfo:
      getStore({
        name: 'userInfo',
      }) || {},
    permissions:
      getStore({
        name: 'permissions',
      }) || [],
    roles: [],
    expires_in:
      getStore({
        name: 'expires_in',
      }) || '',
    access_token:
      getStore({
        name: 'access_token',
      }) || '',
    refresh_token:
      getStore({
        name: 'refresh_token',
      }) || '',
    saleMode: getStore({ name: 'saleMode' }),
    customerInfo: getStore({ name: 'customerInfo' }) || {},
    currentSelectRole: getStore({ name: 'currentSelectRole' }) || '',
    currentSelectMode: getStore({ name: 'currentSelectMode' }) || '',
  },
  actions: {
    // 根据手机号登录
    LoginByPhone({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        loginByMobile(userInfo.mobile, userInfo.code, 'ww152776f45f781b57')
          .then((res) => {
            commit('SET_ACCESS_TOKEN', res.access_token)
            commit('SET_REFRESH_TOKEN', res.refresh_token)
            commit('SET_EXPIRES_IN', res.expires_in)
            commit('SET_USER_INFO', res.user_info)
            commit('SET_PERMISSIONS', res.user_info.authorities || [])
            resolve()
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    // 刷新token
    RefreshToken({ commit, state }) {
      return new Promise((resolve, reject) => {
        refreshToken(state.refresh_token)
          .then((res) => {
            commit('SET_ACCESS_TOKEN', res.access_token)
            commit('SET_REFRESH_TOKEN', res.refresh_token)
            commit('SET_EXPIRES_IN', res.expires_in)
            resolve()
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    // 根据用户名登录
    LoginByUsername({ commit }, userInfo) {
      const user = encryption({
        data: userInfo,
        key: 'pigxpigxpigxpigx',
        param: ['password'],
      })
      return new Promise((resolve, reject) => {
        loginByUsername(user.username, user.password, user.code, user.randomStr)
          .then((res) => {
            if (res.user_info) {
              commit('SET_ACCESS_TOKEN', res.access_token)
              commit('SET_REFRESH_TOKEN', res.refresh_token)
              commit('SET_EXPIRES_IN', res.expires_in)
              commit('SET_USER_INFO', res.user_info)
              commit('SET_PERMISSIONS', res.user_info.authorities || [])
              resolve()
            } else {
              reject(res)
            }
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    // 存放customerInfo
    setCustomerInfo({ commit }, customerInfo) {
      commit('SET_CUSTOMER_INFO', customerInfo)
    },
    setSaleMode({ commit }, saleMode) {
      commit('SET_SALE_MODE', saleMode)
    },
  },
  mutations: {
    SET_ACCESS_TOKEN: (state, access_token) => {
      state.access_token = access_token
      setStore({
        name: 'access_token',
        content: state.access_token,
        type: 'session',
      })
    },
    SET_EXPIRES_IN: (state, expires_in) => {
      state.expires_in = expires_in
      setStore({
        name: 'expires_in',
        content: state.expires_in,
        type: 'session',
      })
    },
    SET_REFRESH_TOKEN: (state, rfToken) => {
      state.refresh_token = rfToken
      setStore({
        name: 'refresh_token',
        content: state.refresh_token,
        type: 'session',
      })
    },
    SET_USER_INFO: (state, userInfo) => {
      state.userInfo = userInfo
      setStore({
        name: 'userInfo',
        content: userInfo,
        type: 'session',
      })
    },
    SET_CUSTOMER_INFO: (state, customerInfo) => {
      state.customerInfo = customerInfo
      setStore({
        name: 'customerInfo',
        content: customerInfo,
        type: 'session',
      })
      setStore({
        name: 'yhnoName',
        content: customerInfo.custName,
        type: 'session',
      })
      setStore({
        name: 'yhno',
        content: customerInfo.yhno,
        type: 'yhno',
      })
      setStore({
        name: 'custLevel',
        content: customerInfo.custLevel,
        type: 'session',
      })
    },
    SET_SALE_MODE: (state, saleMode) => {
      console.log(saleMode)
      state.saleMode = saleMode
      setStore({
        name: 'saleMode',
        content: saleMode,
        type: 'session',
      })
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles
    },
    SET_PERMISSIONS: (state, permissions) => {
      const list = {}
      for (let i = 0; i < permissions.length; i++) {
        list[permissions[i].authority] = true
      }

      state.permissions = list
      setStore({
        name: 'permissions',
        content: list,
        type: 'session',
      })
    },
    UPDATE_PERMISSIONS: (state, permissions) => {
      const list = {}
      for (let i = 0; i < permissions.length; i++) {
        list[permissions[i]] = true
      }

      state.permissions = list
      setStore({
        name: 'permissions',
        content: list,
        type: 'session',
      })
    },
    SET_CURRENT_SELECT_ROLE: (state, role) => {
      state.currentSelectRole = role
      setStore({
        name: 'currentSelectRole',
        content: state.currentSelectRole,
        type: 'session',
      })
    },
    SET_CURRENT_SELECT_MODE: (state, mode) => {
      state.currentSelectMode = mode
      setStore({
        name: 'currentSelectMode',
        content: state.currentSelectMode,
        type: 'session',
      })
    },
  },
}

export default user
