<template>
  <div class="empty-container">
    <van-image
      class="empty-img"
      :src="require('@/assets/search/no-data.png')"
    ></van-image>
    <div class="empty-tip">{{ tip }}</div>
  </div>
</template>

<script>
/**
 * 通用空白页面
 */
export default {
  props: {
    tip: {
      type: String,
      default: '暂无数据',
    },
  },
}
</script>

<style scoped lang="less">
.empty-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 20px 0;

  .empty-img {
    width: 200px;
    margin-top: 100px;
  }

  .empty-tip {
    text-align: center;
    font-size: 14px;
    color: @text-color-tip;
  }
}
</style>
